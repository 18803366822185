import React from "react";
import { Card, CardContent, Typography, Grid, Container, ListItemIcon } from "@mui/material";
import SpeedIcon from "@mui/icons-material/Speed";
import SecurityIcon from "@mui/icons-material/Security";
import CodeIcon from "@mui/icons-material/Code";
import StorageIcon from "@mui/icons-material/Storage";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";

const highlights = [
  {
    icon: <SpeedIcon fontSize="large" />,
    title: "Blazing Fast Performance",
    description: "Our optimized infrastructure ensures ultra-fast response times with global CDN caching.",
  },
  {
    icon: <SecurityIcon fontSize="large" />,
    title: "Enterprise-Grade Security",
    description: "Dedicated VPC, SSL encryption, and automated security updates to keep your data safe.",
  },
  // {
  //   icon: <CodeIcon fontSize="large" />,
  //   title: "Developer-Friendly & Feature-Rich",
  //   description: "One-click deployments, CI/CD integration, and full API support for seamless development.",
  // },
  {
    icon: <StorageIcon fontSize="large" />,
    title: "Flexible Storage & Database Options",
    description: "Supports PostgreSQL, MySQL, and MongoDB with automated backups and data redundancy.",
  },
  {
    icon: <SignalCellularAltIcon fontSize="large" />,
    title: "99.99% Uptime & Reliability",
    description: "High-availability infrastructure with load balancing and real-time performance monitoring.",
  },
  {
    icon: <SupportAgentIcon fontSize="large" />,
    title: "24/7 Expert Support",
    description: "Our dedicated support engineers are available round the clock to assist you anytime.",
  },
];

const HighlightsSection = () => {
  return (
    <Container sx={{ textAlign: "center", paddingY: 5 }} id="highlights">
      {/* Header */}
      <Typography variant="h4" gutterBottom>
        🚀 Strapi Hosting Highlights
      </Typography>
      <Typography variant="body1" sx={{ maxWidth: 800, margin: "auto", marginBottom: 4 }}>
        Discover why our high-performance, secure, and scalable Strapi hosting is the preferred choice for developers and businesses.
      </Typography>

      {/* Highlights Grid - 2 Cards Per Row */}
      <Grid container spacing={4} justifyContent="center">
        {highlights.map((highlight, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card sx={{ height: "100%", boxShadow: 3, padding: 2 }}>
              <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                <ListItemIcon sx={{ fontSize: 50, color: "primary.main" }}>{highlight.icon}</ListItemIcon>
                <Typography variant="h5" sx={{ marginY: 1 }}>
                  {highlight.title}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {highlight.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Call to Action Button */}
      <Button
        variant="contained"
        color="primary"
        endIcon={<ArrowForwardIcon />}
        sx={{ marginTop: 4 }}
        href="#get-started"
      >
        Get Started Now
      </Button>
    </Container>
  );
};

export default HighlightsSection;
