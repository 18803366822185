import * as React from 'react';
import { Container, Typography, Grid, Button, Box } from "@mui/material";

function StripeBuyButton() {
    const handleClick = () => {
        window.location.href = "https://portal.strapi.net/b/3cs9DN9XA4oj7aodQS"; // Replace with your actual trial signup link
    };
    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            sx={{
                textTransform: "none",
                fontSize: "18px",
                fontWeight: "bold",
                padding: "12px 24px",
                borderRadius: "8px",
                backgroundColor: "#4C51BF",
                "&:hover": { backgroundColor: "#3B43A0" },
            }}
        >
            Start Free Trial
        </Button>
    );
}

export default StripeBuyButton;