import React from "react";
import { Container, Typography, Grid, Button, Box } from "@mui/material";
import CloudIcon from "@mui/icons-material/Cloud";
import SecurityIcon from "@mui/icons-material/Security";
import SpeedIcon from "@mui/icons-material/Speed";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import StripeBuyButton from "./StripeBuyButton";

const features = [
    { icon: <SpeedIcon fontSize="large" />, text: "Optimized infrastructure for speed and reliability" },
    { icon: <AutoGraphIcon fontSize="large" />, text: "99.99% uptime guarantee with auto-scaling" },
    { icon: <SecurityIcon fontSize="large" />, text: "Secure hosting with SSL, DDoS protection, and backups" },
    // { icon: <CloudIcon fontSize="large" />, text: "One-click deployments and seamless CI/CD integration" },
];

const HeroSection = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#4C51BF",
                color: "white",
                height: "600px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: 4,
                borderRadius: "20px",
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={4} alignItems="center">
                    {/* Left Side - Text Content */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" fontWeight="bold" gutterBottom>
                            Managed Strapi Hosting
                        </Typography>
                        <Typography variant="h6" sx={{ opacity: 0.9, marginBottom: 3 }}>
                            High-performance, secure, and scalable Strapi solutions.
                        </Typography>
                        {/* Feature List */}
                        <Grid container spacing={2} justifyContent="center">
                            {features.map((feature, index) => (
                                <Grid item xs={12} sm={6} key={index} display="flex" alignItems="center">
                                    <Box sx={{ marginRight: 1 }}>{feature.icon}</Box>
                                    <Typography variant="body1">{feature.text}</Typography>
                                </Grid>
                            ))}
                        </Grid>
                        {/* Call to Action */}
                        {/* <Button
                            variant="contained"
                            size="large"
                            sx={{
                                marginTop: 3,
                                // backgroundColor: "white",
                                // color: "#4C51BF",
                                // "&:hover": { backgroundColor: "#ddd" },
                            }}
                            href="#get-started"
                        >
                            Get Started Now
                        </Button> */}
                        <br />
                        <StripeBuyButton />
                    </Grid>

                    {/* Right Side - Cloud Illustration */}
                    <Grid item xs={12} md={6} display="flex" justifyContent="center">
                        <Box
                            sx={{
                                width: 300,
                                height: 200,
                                backgroundColor: "white",
                                opacity: 0.9,
                                borderRadius: "50%",
                                position: "relative",
                            }}
                        >
                            <Box
                                sx={{
                                    width: 160,
                                    height: 120,
                                    backgroundColor: "white",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: -40,
                                    left: 80,
                                }}
                            />
                            <Box
                                sx={{
                                    width: 100,
                                    height: 100,
                                    backgroundColor: "white",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: -20,
                                    left: 10,
                                }}
                            />
                            <Box
                                sx={{
                                    width: 100,
                                    height: 100,
                                    backgroundColor: "white",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: -20,
                                    right: 10,
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default HeroSection;
