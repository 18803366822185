import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SitemarkIcon() {
  return (
    <SvgIcon sx={{ height: 21, width: 100, mr: 2 }}>
      <svg width="86" height="19" viewBox="0 0 86 19" xmlns="http://www.w3.org/2000/svg">

        <path d="M10 14c-2 0-4-1.5-4-4 0-1.8 1.3-3.3 3-3.8A4 4 0 0114 5c1.5 0 3 1 3 3 2 0 4 1.5 4 4s-2 3-4 3H10z"
          fill="#4C51BF" />


        <text x="25" y="14" font-family="Arial, sans-serif" font-size="12" font-weight="bold" fill="#4C51BF">
          Strapi.Net
        </text>
      </svg>

    </SvgIcon>
  );
}
