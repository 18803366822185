import React from "react";
import { Modal, Box, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const TermsAndConditionsModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="terms-title">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>

        {/* Modal Title */}
        <Typography id="terms-title" variant="h5" fontWeight="bold" gutterBottom>
          Terms and Conditions
        </Typography>

        {/* Properly Formatted Sections */}
        <Typography variant="h6" fontWeight="bold" paragraph>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body2" paragraph>
          By accessing or using our hosting services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree, you must discontinue use immediately.
        </Typography>

        <Typography variant="h6" fontWeight="bold" paragraph>
          2. Service Availability
        </Typography>
        <Typography variant="body2" paragraph>
          While we strive to provide a highly available hosting environment, we do not guarantee uninterrupted access. Scheduled maintenance, unexpected outages, and other disruptions may occur.
        </Typography>

        <Typography variant="h6" fontWeight="bold" paragraph>
          3. No Liability for Data Loss
        </Typography>
        <Typography variant="body2" paragraph>
          We are not responsible for any data loss, corruption, or security breaches that may occur on our servers. You are solely responsible for maintaining proper backups of your data.
        </Typography>

        <Typography variant="h6" fontWeight="bold" paragraph>
          4. No Liability for Financial Loss
        </Typography>
        <Typography variant="body2" paragraph>
          Under no circumstances shall we be held liable for any loss of revenue, business interruption, or consequential damages resulting from service disruptions, downtime, cyber attacks, data breaches, or system failures.
        </Typography>

        <Typography variant="h6" fontWeight="bold" paragraph>
          5. Limitation of Liability
        </Typography>
        <Typography variant="body2" paragraph>
          To the fullest extent permitted by law, our total liability to you for any claims or damages shall not exceed the total amount paid by you for the hosting services in the last 3 months.
        </Typography>

        <Typography variant="body2" paragraph sx={{ fontStyle: "italic", marginTop: 2 }}>
          If you do not agree with any of the above conditions, you must stop using our services immediately.
        </Typography>

        {/* Close Button */}
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 3 }}
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default TermsAndConditionsModal;
