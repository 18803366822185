import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const faqs = [
  {
    question: "What is Strapi hosting?",
    answer: "Strapi hosting is a fully managed solution that allows you to deploy, scale, and secure your Strapi CMS with ease.",
  },
  {
    question: "Do you offer a free trial?",
    answer: "Yes! We offer a free trial so you can experience our platform before committing to a plan.",
  },
  {
    question: "Can I use my own database?",
    answer: "Absolutely! We support PostgreSQL, MySQL, and MongoDB, allowing you to connect your own database.",
  },
  {
    question: "Is my data secure?",
    answer: "Yes, security is our top priority. We provide SSL encryption, DDoS protection, and automated backups to keep your data safe.",
  },
  {
    question: "How does scaling work?",
    answer: "Our infrastructure automatically scales based on your traffic, ensuring optimal performance at all times.",
  },
  {
    question: "Do you provide customer support?",
    answer: "Yes! Our support team is available 24/7 to assist you with any issues or questions you may have.",
  },
];

const FAQSection = () => {
  return (
    <Container id="faq" sx={{ maxWidth: 800, textAlign: "center", paddingY: 5 }}>
      {/* Header */}
      <Typography variant="h4" gutterBottom>
        ❓ Frequently Asked Questions
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 3 }}>
        Here are some common questions about our Strapi hosting. If you need further assistance, feel free to contact us!
      </Typography>

      {/* Accordion List for FAQs */}
      {faqs.map((faq, index) => (
        <Accordion key={index} sx={{ boxShadow: 1, marginBottom: 1 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" color="text.secondary">
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default FAQSection;
