import React from "react";
import { Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CloudIcon from "@mui/icons-material/Cloud";
import SecurityIcon from "@mui/icons-material/Security";
import SpeedIcon from "@mui/icons-material/Speed";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import StorageIcon from "@mui/icons-material/Storage";
import BackupIcon from "@mui/icons-material/Backup";
import ExtensionIcon from "@mui/icons-material/Extension";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const features = [
    { icon: <CloudIcon fontSize="medium" />, text: "Fully Managed Strapi Hosting – Optimized for speed, security, and reliability." },
    { icon: <SecurityIcon fontSize="medium" />, text: "Dedicated VPC (Virtual Private Cloud) – Ensuring data isolation and enterprise-grade security." },
    // { icon: <SpeedIcon fontSize="medium" />, text: "Auto-Scaling & Performance Optimization – Handle traffic spikes effortlessly." },
    { icon: <SecurityIcon fontSize="medium" />, text: "Security-First Approach – DDoS protection, automated backups, and SSL encryption." },
    { icon: <AutoGraphIcon fontSize="medium" />, text: "99.99% Uptime SLA – High availability infrastructure with global distribution." },
    { icon: <SupportAgentIcon fontSize="medium" />, text: "24/7 Expert Support – Always available to assist you with setup and troubleshooting." },
    { icon: <BackupIcon fontSize="medium" />, text: "Automated Backups & Restore – Peace of mind with scheduled and on-demand backups." },
];

const addons = [
    // { icon: <StorageIcon fontSize="medium" />, text: "Custom Storage & Database Options – Choose between PostgreSQL, MySQL, or MongoDB." },
    { icon: <AutoGraphIcon fontSize="medium" />, text: "Advanced Analytics & Monitoring – Gain insights into your Strapi performance and user activity." },
    { icon: <ExtensionIcon fontSize="medium" />, text: "Plugin & Extension Support – Easily integrate third-party plugins to enhance functionality." },
];

const FeaturesSection = () => {
    return (
        <Card id="features" sx={{ maxWidth: 650, margin: "auto", padding: 3, boxShadow: 3 }}>
            <CardContent>
                {/* <Typography variant="h5" gutterBottom>
                    Key Features
                </Typography> */}
                <List>
                    {features.map((feature, index) => (
                        <ListItem key={index} sx={{ alignItems: "center" }}>
                            <ListItemIcon sx={{ minWidth: 50 }}>{feature.icon}</ListItemIcon>
                            <ListItemText primary={feature.text} />
                        </ListItem>
                    ))}
                </List>

                <Typography variant="h6" sx={{ marginTop: 2 }}>
                    Add-Ons & Extras
                </Typography>
                <List>
                    {addons.map((addon, index) => (
                        <ListItem key={index} sx={{ alignItems: "center" }}>
                            <ListItemIcon sx={{ minWidth: 50 }}>{addon.icon}</ListItemIcon>
                            <ListItemText primary={addon.text} />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default FeaturesSection;
