import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppTheme from '../shared-theme/AppTheme';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Features from './components/Features';
import Typography from '@mui/material/Typography';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
// import TermsAndConditions from './components/TermsAndConditions';
import TermsModal from './components/TermsModal';
import { Box } from '@mui/material';
import PrivacyPolicyModal from './components/PrivacyPolicyModal';


export default function MarketingPage(props) {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const onModal = (section = 'terms') => {
    if (section === 'terms') {
      setShowTerms(!showTerms);
    }
    if (section === 'privacy') {
      setShowPrivacy(!showPrivacy);
    }
  }
  //PrivacyPolicyModal

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <AppAppBar />
      <Hero onTerms={() => onModal('terms')} />
      <div>
        {!showTerms && <>
          {/* <LogoCollection /> */}
          <Features />
          <Divider />
          {/* <Testimonials />
          <Divider /> */}
          <Highlights />
          <Divider />
          <Pricing />
          <Divider />
          <FAQ />
          <Divider />
        </>
        }
        {showTerms && <TermsModal open={showTerms} onClose={() => onModal('terms')}
        />
        }
        {showPrivacy && <PrivacyPolicyModal open={showPrivacy} onClose={() => onModal('privacy')}
        />
        }
        <Footer onTerms={() => onModal('terms')} onPrivacy={() => onModal('privacy')} />
      </div>
    </AppTheme>
  );
}
