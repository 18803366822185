import React from "react";
import { Modal, Box, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const PrivacyPolicyModal = ({ open, onClose }) => {
    return (
        <Modal open={open} onClose={onClose} aria-labelledby="privacy-title">
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 500,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 3,
                    borderRadius: 2,
                    maxHeight: "80vh",
                    overflowY: "auto",
                }}
            >
                {/* Close Button */}
                <IconButton
                    onClick={onClose}
                    sx={{ position: "absolute", top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>

                {/* Modal Title */}
                <Typography id="privacy-title" variant="h5" fontWeight="bold" gutterBottom>
                    Privacy Policy
                </Typography>

                {/* Privacy Policy Content */}
                <Typography variant="h6" fontWeight="bold" paragraph>
                    1. Data Collection
                </Typography>
                <Typography variant="body2" paragraph>
                    We collect personal and non-personal information to provide, improve, and secure our hosting services. This includes, but is not limited to, names, email addresses, and server logs.
                </Typography>

                <Typography variant="h6" fontWeight="bold" paragraph>
                    2. No Data Selling Policy
                </Typography>
                <Typography variant="body2" paragraph>
                    We value your privacy. We **do not sell, rent, or share** your personal data with third parties for marketing or commercial purposes.
                </Typography>

                <Typography variant="h6" fontWeight="bold" paragraph>
                    3. How We Use Your Data
                </Typography>
                <Typography variant="body2" paragraph>
                    We use collected data solely for the purposes of service operation, security improvements, fraud prevention, and customer support. We may analyze anonymized data for analytics.
                </Typography>

                <Typography variant="h6" fontWeight="bold" paragraph>
                    4. Data Protection & Security
                </Typography>
                <Typography variant="body2" paragraph>
                    We implement strong security measures, including encryption, firewalls, and access controls, to protect your personal information from unauthorized access or misuse.
                </Typography>

                <Typography variant="h6" fontWeight="bold" paragraph>
                    5. Third-Party Services
                </Typography>
                <Typography variant="body2" paragraph>
                    Some features may require integration with third-party services. However, we ensure that such integrations comply with strict data protection policies.
                </Typography>

                <Typography variant="h6" fontWeight="bold" paragraph>
                    6. Cookies and Tracking
                </Typography>
                <Typography variant="body2" paragraph>
                    We use cookies and tracking technologies to enhance user experience, monitor site performance, and analyze trends. You can manage cookie preferences via browser settings.
                </Typography>

                <Typography variant="h6" fontWeight="bold" paragraph>
                    7. Data Retention & Deletion
                </Typography>
                <Typography variant="body2" paragraph>
                    We retain personal data only as long as necessary to fulfill legal, operational, or security obligations. Users may request data deletion by contacting support.
                </Typography>

                <Typography variant="h6" fontWeight="bold" paragraph>
                    8. Policy Updates
                </Typography>
                <Typography variant="body2" paragraph>
                    We may update this policy periodically. Continued use of our services after modifications constitutes acceptance of the revised terms.
                </Typography>

                <Typography variant="body2" paragraph sx={{ fontStyle: "italic", marginTop: 2 }}>
                    If you have questions about our Privacy Policy, please contact us.
                </Typography>

                {/* Close Button */}
                <Button
                    variant="contained"
                    fullWidth
                    sx={{ mt: 3 }}
                    onClick={onClose}
                >
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default PrivacyPolicyModal;
