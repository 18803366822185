import * as React from 'react';

function StripeBuyCard() {
    // Paste the stripe-buy-button snippet in your React component
    return (
        <stripe-buy-button
            buy-button-id="buy_btn_1QqVm8Ihkktq8pQxnAwddpqJ"
            publishable-key="pk_live_51Oi8HIIhkktq8pQxiat5giMjAEiO7r8RzSEKchXUz2VRSz33VoeAGPHMCXas1ssRYSgMlO44CtKaniegELMQ3yLl00kNAfB3zO"
        >
        </stripe-buy-button>
    );


}

export default StripeBuyCard;